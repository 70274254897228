import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

const breadCrumbLevels = {
  Home: "/",
  // Blog: "/blog",
  Impressum: "de/impressum"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/imprint"
);

const Impressum = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Impressum"
        description="Impressum mit Angaben zum Urheber und der Webseiteninhalte, sowie auch zum Copyright."
        lang="de"
        alternateLangs={alternateLangs}
      />
      <MainContent article={false}>
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />

        <H as="h1">Impressum</H>
        <p>Betreiber der Webseite bluerivermountains.com:</p>
        <p>
          Matthias Kupperschmidt
          <br />
          Frederikssundsvej 22
          <br />
          2400 Kopenhagen
          <br />
          Dänemark
        </p>
        <p className="baseline">
          Email: <a href="mailto:info@bluerivermountains.com">info@bluerivermountains.com</a>
          <br />
          Telefon: +45 93 86 85 64
        </p>
        <p className="baseline">CVR: 39474263</p>
        <p>Diese Seite ist ein Copyright von Matthias Kupperschmidt.</p>
        <H as="h2">Rechtshinweis</H>
        <p>
          Ich überprüfe die Informationen auf dieser Website regelmäßig. Trotz aller Sorgfalt können sich die Daten
          zwischenzeitlich verändert haben. Eine Haftung oder Garantie für die Aktualität, Richtigkeit und
          Vollständigkeit der Informationen kann daher nicht übernommen werden. Gleiches gilt auch für Websites, auf
          die über Hyperlink verwiesen wird. Matthias Kupperschmidt ist für den Inhalt der Websites, die aufgrund
          einer solchen Verbindung erreicht werden, nicht verantwortlich. Die Vervielfältigung von Informationen oder
          Daten – insbesondere die Verwendung von Texten, Textteilen oder Bildmaterial – bedarf der vorherigen
          Zustimmung. Dies gilt auch für alle Blogeinträge. Ich weise darauf hin, dass ich alle in die Suchmaske
          eingegebenen Daten nur zweckgebunden speichere* und diese Daten weder für eigene Werbung nutze, noch an
          Dritte weitergeben.
        </p>
        <p>
          Weitere Infos finden Sie auf meiner <Link to="/de/datenschutz">Datenschutzerklärung</Link>.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);
export default Impressum;
